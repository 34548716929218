import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'

import TooltipDirective from './directive/tooltip';

createApp(App)
    .use(store)
    .use(router)
    .directive('tooltip', TooltipDirective)
    .mount('#app')

// try {
//     require('electron-reloader')(module);
//   } catch (_) {}

import axios from 'axios';

// 添加请求拦截器
axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        // 从存储中获取 token
        const token = localStorage.getItem('token');

        if (token) {
            // 如果 token 存在，添加到请求头
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        // 任何状态码都在2xx范围内的响应都会触发此函数
        // 这意味着你可以对正常返回的响应数据进行特定的处理
        return response;
    },
    function (error) {
        // 对响应错误做点什么
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                // 如果返回的状态是401或403，重定向至登录页面
                router.push('/login');
            }
        } else {
            console.error('Server response not received due to possible network issues or server downtime.');
        }
        return Promise.reject(error);
    }
);