<template>
    <div>
        <div id="sidebar" :style="sidebarStyle">
            <hr class="sb_filter_hr">
            <p class="sb_filter" >主页</p>
            <span><router-link to="/MyCenter" exact-active-class="eac"
                    title="我的信息"><i>我的信息</i></router-link></span>
            <!-- <span><router-link to="/CalenderView" exact-active-class="eac" class="iconfont icon-calendar"
                    title="日历笔记"><i>日历笔记</i></router-link></span> -->
            <span><router-link to="/ChatGpt" exact-active-class="eac"
                    title="ChatGPT"><i>ChatGPT</i></router-link></span>
            <!-- <span><router-link to="/SysInfo" exact-active-class="eac"
                    title="关于"><i>关于</i></router-link></span> -->
            <hr class="sb_filter_hr">
            <p class="sb_filter" >出勤</p>
            <span><router-link to="/AbsentApply" exact-active-class="eac"
                    title="休假管理"><i>休假管理</i></router-link></span>
            <span><router-link to="/OverTime" exact-active-class="eac"
                    title="加班管理"><i>加班申请</i></router-link></span>
            <span><router-link to="/BusinessTrip" exact-active-class="eac"
                    title="加班管理"><i>出差申请</i></router-link></span>
            <hr class="sb_filter_hr">
            <p class="sb_filter" >订单</p>
            <span><router-link to="/ForeignOrder" exact-active-class="eac"
                    title="外贸订单"><i>外贸订单</i></router-link></span>
            <span><router-link to="/DomesticOrder" exact-active-class="eac"
                    title="内贸订单"><i>内贸订单</i></router-link></span>
            <span><router-link to="/OrderBin" exact-active-class="eac"
                    title="回收站"><i>回收站</i></router-link></span>
            <hr class="sb_filter_hr">
            <p class="sb_filter" >财务</p>
            <span><router-link to="/PaymentApprove" exact-active-class="eac"
                    title="打款"><i>打款</i></router-link></span>
            <span v-if="getPermission(3)"><router-link to="/ExportIncome" exact-active-class="eac"
                    title="收汇"><i>收汇</i></router-link></span>
            <span><router-link to="/ShipmentInfo" exact-active-class="eac"
                    title="出货"><i>出货</i></router-link></span>
            <span><router-link to="/ClearenceInfo" exact-active-class="eac"
                    title="通关单"><i>通关单</i></router-link></span>
            <hr class="sb_filter_hr">
            <p class="sb_filter" >信息</p>
            <span><router-link to="/SupplierInfo" exact-active-class="eac"
                    title="供应商"><i>供应商</i></router-link></span>
            <span><router-link to="/ClientInfo" exact-active-class="eac"
                    title="客户"><i>客户</i></router-link></span>
            <hr class="sb_filter_hr" v-if="getPermission(6)">
            <p class="sb_filter"  v-if="getPermission(6)">数据</p>
            <span v-if="getPermission(6)"><router-link to="/DataInfo" exact-active-class="eac"
                    title="订单数据"><i>订单数据</i></router-link></span>
            <hr class="sb_filter_hr" v-if="getPermission(6)">
            <p class="sb_filter"  v-if="getPermission(6)">后台</p>
            <span v-if="getPermission(6)"><router-link to="/UserManage" exact-active-class="eac"
                    title="用户管理"><i>用户管理</i></router-link></span>
            <span v-if="getPermission(6)"><router-link to="/SysConfig" exact-active-class="eac"
                    title="系统参数"><i>系统参数</i></router-link></span>
        </div>


        <div id="routerView_outerbox" :style="routerView_outerbox_style">
            <div id="routerView">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
// import WarningComp from './WarningComp.vue';
import CryptoJS from 'crypto-js';
export default {
    name: "SideBarComp",
    components: {

    },
    data() {
        return {
            sidebarResize: true,
            sidebarStyle: {
                width: "100px",
            },
            routerView_outerbox_style: {
                left: "100px",
                width: "calc(100% - 100px)",
            },
            //localstorage加密
            secret_key: "1rfsvx41a46BW1Bvxh",
        }
    },
    methods: {
        decrypt(encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, this.secret_key);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        },
        getPermission(n) {
            let permissions = JSON.parse(this.decrypt(localStorage.getItem('permissions')));
            return permissions[n].value;
        },
    },
    mounted() {

    }
}
</script>

<style scoped>
.WarningComp-enter-active,
.WarningComp-leave-active {
    transition: opacity 0.2s;
}

.WarningComp-enter,
.WarningComp-leave-to {
    opacity: 0;
}

#WarningComp {
    display: flex;
    position: absolute;
    top: 140px;
    right: 50px;
    width: fit-content;
    line-height: 32px;
    height: 32px;
    font-size: 0.8rem;
    background-color: var(--blue-color);
    border-radius: 5px;
    padding: 0 10px 0 10px;
    color: white;
    z-index: 100000;
}

#sidebar {
    height: 100%;
    min-height: 400px;
    background-color: var(--font-color);
    position: absolute;
    text-align: center;
    z-index: 1;
    padding: 0 0 10px 0;
    box-sizing: border-box;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: 1px !important;
    transition: 0.1s;
}

#sidebar::-webkit-scrollbar {
    width: 2px !important
}

#sidebar>span {
    font-size: 0.6rem;
    display: block;
    line-height: 40px;
    height: 40px;
    width: 100%;
    cursor: pointer;
    text-align: left;
    border-radius: 5px;
    padding: 0 5px 2px 5px;
    display: flex;
    overflow: hidden;
}

#sidebar a:hover {
    color: var(--thirdfont-color);
}

#sidebar a {
    width: 100%;
    height: 100%;
    line-height: 35px;
    border-radius: 3px;
    overflow: hidden;
    transition: 0.1s;
    padding-left: 5px;
    color: white;
    display: flex;
}

#sidebar i {
    display: inline-block;
    line-height: 35px;
    width: 80px;
    padding-left: 10px;
    font-style: normal;
    font-size: 0.6rem;
    overflow: hidden;
}


.sb_filter {
    font-size: 0.6rem;
    line-height: 26px;
    color: var(--subfont-color);
    margin-top: 5px;
    text-align: left;
    transition: 0.1s;
    margin-left: 20px;
}

.sb_filter_hr {
    border: 1px solid var(--subfont-color);
}

</style>