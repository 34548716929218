export default {
    mounted(el, binding) {
        const tooltip = document.createElement('div');
        tooltip.classList.add('tooltip');
        tooltip.innerText = binding.value;
        tooltip.style.display = 'none';
        tooltip.style.position = 'absolute';
        tooltip.style.fontWeight = '400';
        el.appendChild(tooltip);

        let timer;
        console.log();
        el.addEventListener('mouseenter', (e) => {
            timer = setTimeout(() => {
                tooltip.style.display = 'block';
                tooltip.style.top = e.target.offsetHeight + 6 + 'px';
                tooltip.style.left = e.target.offsetWidth / 2 - tooltip.getBoundingClientRect().width /2 + 'px';
            }, 200);
        });

        el.addEventListener('mouseleave', () => {
            clearTimeout(timer);
            tooltip.style.display = 'none';
        });
    },
};